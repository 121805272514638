import React from 'react';

const Biography = () => {
    return (
        <div className="biography__section" id="about">
            <div className="biography__container">
                <div className="biography__block biography__description" data-aos="zoom-in-down" data-aos-delay="600">
                    <div className="section__label">— Introduction</div>
                    <div className="biography__intro">Hello! I'm Donald Tang, a software engineer based in New York City.</div>
                    <div className="biography__subheading">
                        <p>I enjoy building things, whether its websites or applications, that serve to solve a problem.</p>
                        <p>After graduating from Stevens Institute of Technology, I started working on self developed projects while learning new technology stacks on my own.
                            Later, I began my career by joining the development team at Edgi Learning, an educational startup, before joining Virgin Pulse, and then Radian, in which I've had the opportunity to work
                            in small and large teams, wear many different hats, and develop solutions across a variety of different technology stacks.
                        </p>
                        <p>I'm currently open to and seeking full time opportunities. I can be reached through my email at <a href="mailto:donaldtang08@gmail.com" target="_blank" rel="noreferrer">donaldtang08@gmail.com</a>.</p>
                    </div>
                </div>
                {/* <div className="biography__block biography__tech">
                    <div className="biography__tech--card">
                        <div className="biography__tech--heading">Some technologies I've worked with:</div>
                        <div className="biography__tech--item">
                            <div className="biography__tech--item-title">Front End</div>
                            <ul className="biography__tech--item-list">
                                <li>HTML/CSS</li>
                                <li>Javascript</li>
                                <li>React</li>
                            </ul>
                        </div>
                        <div className="biography__tech--item">
                            <div className="biography__tech--item-title">Back End</div>
                            <ul className="biography__tech--item-list">
                                <li>NodeJs</li>
                                <li>ExpressJs</li>
                                <li>MongoDB</li>
                            </ul>
                        </div>
                        <div className="biography__tech--item">
                            <div className="biography__tech--item-title">More</div>
                            <ul className="biography__tech--item-list">
                                <li>Java</li>
                                <li>Wordpress</li>
                                <li>Python</li>
                            </ul>
                        </div>
                    </div>
                </div> */}
                <div className="biography__block biography__block--console" data-aos="zoom-in-down" data-aos-delay="200">
                    <div className="console__container">
                        <div className="console__dots">
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                        </div>
                        <div className="console__body">
                            <div className="console__body--heading">technologies i've worked with</div>
                            <div className="console__body--heading">front-end</div>
                                <ul className="console__body--results">
                                    <li>HTML/CSS</li>
                                    <li>Javascript</li>
                                    <li>React</li>
                                    <li>Angular</li>
                                </ul>
                            <div className="console__body--heading">back-end</div>
                                <ul className="console__body--results">
                                    <li>NodeJs</li>
                                    <li>Spring Boot</li>
                                    <li>MongoDB</li>
                                    <li>SQL</li>
                                </ul>
                            <div className="console__body--heading">more</div>
                                <ul className="console__body--results">
                                    <li>TypeScript</li>
                                    <li>Java</li>
                                    <li>Python</li>
                                    <li>Docker</li>
                                </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Biography;
